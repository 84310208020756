// class QuestDefaultStrategy {
//
//     constructor(questContext) {
//         this.questContext = questContext
//         //this.typeChallenge = this.questContext.questJson.ordered[0].typeChallenge;
//
//         if (this.questContext.questJson.ordered[0].typeChallenge === 'smpnum' || this.questContext.questJson.ordered[0].bigTarget) {
//             this.typeChallenge = 'smpnum';
//         }else {
//             this.typeChallenge = 'int';
//         }
//
//         this.initStartingValue();
//         this.initFirstChallenge();
//     }
let SMPNum = require("../../src/SMPNum");

//let moment = require('moment')

class BalanceKpiUtils  {

    constructor (balanceBuilder) {
        this.balanceBuilder = balanceBuilder;
    }


    getTimeToKillGhostInSMPNum (level,hero,hitPerSeconds) {
        let smpNumHpGhost = this.balanceBuilder.gameplay.HPGhost(level);

        let dmg = this.balanceBuilder.gameplay.DMGHero(hero);

        let hits = SMPNum.divSmpNum(smpNumHpGhost,dmg);

        let one = new SMPNum(1);
        if (SMPNum.greaterThan(one,hits)) {
            hits = one;
        }

        let seconds = SMPNum.divSmpNum(hits,new SMPNum(parseInt(hitPerSeconds)));

        return seconds;
    }


    getTimeToKillBoss (level, hero, hitPerSeconds) {
        let smpNumHpBoss = this.balanceBuilder.gameplay.HPBoss(level);
        let dmg = this.balanceBuilder.gameplay.DMGHero(hero);
        let hits = SMPNum.divSmpNum(smpNumHpBoss,dmg);
        let one = new SMPNum(1);
        if (SMPNum.greaterThan(one, hits)) {
            hits = one;
        }
        let seconds = SMPNum.divSmpNum(hits, new SMPNum(parseInt(hitPerSeconds)));
        return SMPNum.ToReadableTimeValue(seconds);
    }

    getTimeToKillBossValueOnly (level, hero, hitPerSeconds) {
        let smpNumHpBoss = this.balanceBuilder.gameplay.HPBoss(level);
        let dmg = this.balanceBuilder.gameplay.DMGHero(hero);
        let hits = SMPNum.divSmpNum(smpNumHpBoss,dmg);
        let one = new SMPNum(1);
        if (SMPNum.greaterThan(one, hits)) {
            hits = one;
        }
        let seconds = SMPNum.divSmpNum(hits, new SMPNum(parseInt(hitPerSeconds)));
        return seconds;
        //return SMPNum.ToReadableTimeValue(seconds);
    }

    getTimeToKillGhost (level,hero,hitPerSeconds) {

        let smpNumHpGhost = this.balanceBuilder.gameplay.HPGhost(level);

        let dmg = this.balanceBuilder.gameplay.DMGHero(hero);

        let hits = SMPNum.divSmpNum(smpNumHpGhost,dmg);

        let one = new SMPNum(1);
        if (SMPNum.greaterThan(one,hits)) {
            hits = one;
        }

        let seconds = SMPNum.divSmpNum(hits,new SMPNum(parseInt(hitPerSeconds)));


        return SMPNum.ToReadableTimeValue(seconds);

        // if (mode === 's') {
        //
        //     let suffix = 'seconds';
        //     let value = seconds;
        //     if (SMPNum.greaterThan(seconds,new SMPNum(60))) {
        //         value = SMPNum.divSmpNum(seconds,new SMPNum(60));
        //         suffix = 'minutes';
        //
        //         if (SMPNum.greaterThan(value,new SMPNum(60))) {
        //             value = SMPNum.divSmpNum(value,new SMPNum(60));
        //             suffix = 'hours';
        //
        //             if (SMPNum.greaterThan(value,new SMPNum(24))) {
        //                 value = SMPNum.divSmpNum(value,new SMPNum(24));
        //                 suffix = 'days';
        //
        //                 if (SMPNum.greaterThan(value,new SMPNum(7))) {
        //                     value = SMPNum.divSmpNum(value,new SMPNum(7));
        //                     suffix = 'week(s)';
        //
        //                     if (SMPNum.greaterThan(value,new SMPNum(4))) {
        //                         value = SMPNum.divSmpNum(value,new SMPNum(4));
        //                         suffix = 'month(s)';
        //                         if (SMPNum.greaterThan(value,new SMPNum(12))) {
        //                             value = SMPNum.divSmpNum(value,new SMPNum(12));
        //                             suffix = 'year(s)';
        //                             if (SMPNum.greaterThan(value,new SMPNum(100))) {
        //                                 value = SMPNum.divSmpNum(value,new SMPNum(100));
        //                                 suffix = 'century(s)';
        //
        //                                 if (SMPNum.greaterThan(value,new SMPNum(10))) {
        //                                     value = SMPNum.divSmpNum(value,new SMPNum(10));
        //                                     suffix = 'millenaire(s)';
        //                                 }
        //                             }
        //                         }
        //                     }
        //                 }
        //             }
        //         }
        //     }
        //     return value.ToReadableAlphabetV2() + ' ' + suffix;
        //
        //
        //
        // }else if (mode === 'm'){
        //     let temp = SMPNum.divSmpNum(seconds,new SMPNum(60))
        //     return temp.ToReadableAlphabetV2() + ' mn'
        // }else {
        //     return seconds.ToReadableAlphabetV2() + ' seconds';
        // }



    }

    getTotalHitsRequiredToKillAGhostAtLevel (level,hero) {

        let smpNumHpGhost = this.balanceBuilder.gameplay.HPGhost(level);

        let dmg = this.balanceBuilder.gameplay.DMGHero(hero);

        let hits = SMPNum.divSmpNum(smpNumHpGhost,dmg);

        let one = new SMPNum(1);
        if (SMPNum.greaterThan(one,hits)) {
            return "1";
        }else {
            return hits.ToReadableAlphabetV2();
        }


        //i need the ghost HP
        //i need the player DPS
    }

}

export default BalanceKpiUtils;
