import Tools from "../utils/Tools";
let mathjs = require('mathjs');


class SupportModelUtils {
    constructor() {

    }

    getSupportList(skillList) {
        let listGrouped = Tools.groupBy(skillList,'supportData','m_iID');
        let supportIds = Object.keys(listGrouped);
        let supportResult = [];
        for (let i=0;i<supportIds.length;i++) {
            let support = listGrouped[supportIds[i]][0].supportData;
            supportResult.push(support);
        }
        return supportResult;
    }

    getListGrouped(skillList) {
        let listGrouped = Tools.groupBy(skillList, 'supportData', 'm_iID');
        return listGrouped;
    }


    getSupportDataByName(skillList, supportName) {
        let listGrouped = this.getListGrouped(skillList);

        let supportIndexMatchingName = -1;

        let keys = Object.keys(listGrouped);

        keys.forEach((key) => {
            if (listGrouped[key][0].support.toLowerCase() === supportName.toLowerCase()) {
                supportIndexMatchingName = key;
            }
        })

        if (supportIndexMatchingName !== -1) {
            let support = listGrouped[supportIndexMatchingName][0].supportData;
            support.name = support.m_sName;
            support.skillList = listGrouped[supportIndexMatchingName];
            return support;
        } else {
            return null;
        }
    }

    getSupportDataById(skillList, supportId) {
        let listGrouped = this.getListGrouped(skillList);

        let supportIndexMatchingName = -1;

        let keys = Object.keys(listGrouped);

        keys.forEach((key) => {
            if (listGrouped[key][0].supportData.m_iID === supportId) {
                supportIndexMatchingName = key;
            }
        })

        if (supportIndexMatchingName !== -1) {
            let support = listGrouped[supportIndexMatchingName][0].supportData;
            support.name = support.m_sName;
            support.skillList = listGrouped[supportIndexMatchingName];
            return support;
        } else {
            return null;
        }
    }

    getSkillMatchingType(skillList, supportId, skillType) {
        let supportData = this.getSupportDataById(skillList, supportId);

        let res = supportData.skillList.filter(skill => {
            return (skill.skillType === skillType);
        })

        return res;
    }


    computeEvolve  (teamPossibleLevelUp,  {m_skillPercent, m_iCurrentLevel}) {
// What is the rule, the rule
        /*
        * The rule is as follow:
        *    - 40, 40, 34 m_iCurrentLevel
        *
        *   m_skill_percent => .
        *    0.5  evolve counter 1 * skill
        *    1401
        *    if (evolveCounter > 1000) {
        *       evolveCounter = 2
        *    }
        *
        * */

        //m_skillPercent = Math.float(m_skillPercent);
        if (typeof m_skillPercent === 'string') {
            m_skillPercent = parseFloat(m_skillPercent);
        }

        let total = 0;
        let evolveCounter = 1;

        if (teamPossibleLevelUp >= 1000) {

            evolveCounter =  Math.floor(teamPossibleLevelUp/1000) + 1;
            let notYetBoostSkillWithCurrentEvolve = m_iCurrentLevel >= (teamPossibleLevelUp % 1000);
            if (notYetBoostSkillWithCurrentEvolve) {
                evolveCounter = evolveCounter - 1;
            }

            total = m_skillPercent * evolveCounter;
            return total;
            //return (parseInt(m_skillPercent) * (evolveCounter + 1)) + total;

        } else {
            if (teamPossibleLevelUp >= m_iCurrentLevel) {
                return m_skillPercent;
            }
            return total;

        }

    }


    getSupportCumulatedPercent(skillList,supportId,skillType,teamPossibleLevelUp){
        //la est-ce que je fais les mult
       //la je
        //en fait sur le cumul y a dexu choses, on fait le cumul sur les skills.
       //mais sans les perdre

        let res = 0;
        let listSkillsMatchingTYpe = this.getSkillMatchingType(skillList,supportId,skillType);
        listSkillsMatchingTYpe.forEach(skill => {
            res = res + this.computeEvolve(teamPossibleLevelUp,skill);
        })
        res = mathjs.format(res,{precision:14});
        return parseFloat(res);
    }


    getUnlockedTeam(skillList,teamSize) {

        let listGrouped = Tools.groupBy(skillList, 'support', null);

        let totalSupport = teamSize;

        let availableSupport = [];
        let supportNames = Object.keys(listGrouped);

        supportNames.forEach(s => {
            let support = listGrouped[s];
            let supportId = support[0].supportData.m_iID
            if (supportId <= totalSupport) {
                let supportData = {};
                supportData.m_iID = supportId;
                supportData.name = s;
                supportData.icon = supportId + '.png';
                supportData.data = support;
                supportData.supportId = supportId;
                availableSupport.push(supportData);
            }
        })

        return availableSupport;
    }

    computeSkillBonus(skillType,skillList,teamPossibleLevelUp,teamSize) {

        let team = this.getUnlockedTeam(skillList,teamSize);
        let skillValue = 0.0
        team.forEach(support => {
            skillValue = skillValue + this.getSupportCumulatedPercent(skillList,support.m_iID,skillType,teamPossibleLevelUp);
        })

        //in game play, this support skill value not divide with 100
        //but in this editor, we have divide, so we need to multiple with 100
        skillValue = skillValue * 100;

        skillValue = parseFloat(mathjs.format(skillValue,{precision:14}));

        return skillValue;
        //return 0;
    }


}

let instance = new SupportModelUtils();

export default instance
