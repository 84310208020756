<template>
  <v-app>

<!--    <HighNumberVisualizer   :key="this.$store.state.balanceBuilderVersion" pTitle="Base Money" pHokMokChartType="BASE_MONEY" :should-init="true" />-->

<!--    <HighNumberVisualizer   :key="this.$store.state.balanceBuilderVersion"-->
<!--                            pTitle="HP_GHOST"-->
<!--                            pHokMokChartType="HP_GHOST" :should-init="true" />-->


    <KpiDashboard :key="this.$store.state.balanceBuilderVersion" />

    <FooterLayout></FooterLayout>



  </v-app>


<!--    -->
<!--    <BalanceParameters></BalanceParameters>-->
<!--  <Footer></Footer>-->


</template>

<script>

//import HighNumberVisualizer  from "../components/charts/HighNumberVisualizer";
import FooterLayout  from "../components/commons/FooterLayout";

import KpiDashboard from '../components/gameplay/kpi/KpiDashboard.vue';

//import Footer from "../components/commons/Footer";
//import BalanceParameters from "../components/gameplay/BalanceParameters";

export default {
  data() {
    return {
      dialog: false,
      notifications: false,
      sound: true,
      widgets: false,

    }
  },
  components: {
    //HighNumberVisualizer: HighNumberVisualizer,
    FooterLayout: FooterLayout,
    KpiDashboard: KpiDashboard
    // Footer : Footer,
    //  BalanceParameters : BalanceParameters
    //pieChart:pieChart,
    //radarChart: radarVisualizer
  },


}

</script>
