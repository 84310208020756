<template>
    <!--  <v-container -->

    <!--  >-->

    <v-container
        fluid

    >

        <v-dialog
            v-model="showSupportDetail"
        >
            <v-card
                min-width="500"
                min-height="500"
            >

                <v-simple-table>
                    <template v-slot:default>
                        <thead>
                        <tr>
                            <th class="text-left">
                                Label
                            </th>
                            <th class="text-left">
                                KPI Value
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr
                        >
                            <td>Money available for all support</td>
                            <td>{{ kpiRemainingMoneyAvailableForSupport.ToReadableAlphabetV2() }}</td>
                        </tr>
                        <tr
                        >
                            <td>Cumulated money to bring this support to this level</td>
                            <td> {{ kpiCumulatedSpentCoinsToLevelUpSupport.ToReadableAlphabetV2() }}</td>
                        </tr>
                        <tr
                        >
                            <td>Money Remain</td>
                            <td>N/A</td>
                        </tr>
                        <tr
                        >
                            <td>Support Name</td>
                            <td> {{ kpiSupportLabelInfos }}</td>
                        </tr>
                        <tr
                        >
                            <td>Cumulated money to bring this support to this level</td>
                            <td> {{ kpiCumulatedSpentCoinsToLevelUpSupport.ToReadableAlphabetV2() }}</td>
                        </tr>
                        <tr
                        >
                            <td>Possible Level Up</td>
                            <td> {{ kpiSupportPossibleLevelUp.ToReadableAlphabetV2() }}</td>
                        </tr>
                        <tr
                        >
                            <td> Support standard DPS</td>
                            <td> {{ kpiSupportDpsStandard.ToReadableAlphabetV2() }}</td>
                        </tr>
                        <tr
                        >
                            <td> Cost to level up to the next level</td>
                            <td> (calculate previous value + 1) and compare</td>
                        </tr>

                        <tr
                        >
                            <td> SUPPORT_TAP_DAMAGE</td>
                            <td> {{ kpiSupportTapDamage.ToReadableAlphabetV2() }}</td>
                        </tr>

                        </tbody>
                    </template>
                </v-simple-table>
                Liste of sills unlocked for support at this level <br/>
                <v-simple-table>
                    <template>
                        <tbody>


                        <tr
                        >
                            <td> Skill TAP_DAMAGE</td>
                            <td> {{ kpiSupportSkillTapDamage }}</td>
                        </tr>

                        <tr
                        >
                            <td> Skill SUPPORT_DAMAGE</td>
                            <td> {{ kpiSupportSkillSupportDamage }}</td>
                        </tr>

                        <tr
                        >
                            <td> Skill ALL_DAMAGE</td>
                            <td> {{ kpiSupportSkillAllDamage }}</td>
                        </tr>

                        <tr
                        >
                            <td> Skill CAT BONUS</td>
                            <td> {{ kpiSupportCatBonus }}</td>
                        </tr>

                        <tr
                        >
                            <td> Skill CRITICAL CHANCE</td>
                            <td> {{ kpiSupportCriticalChance }}</td>
                        </tr>

                        <tr
                        >
                            <td> Skill MANA BONUS</td>
                            <td> {{ kpiSupportManaBonus }}</td>
                        </tr>

                        <tr
                        >
                            <td> Skill CRITICAL DAMAGE</td>
                            <td> {{ kpiSupportCriticalDamage }}</td>

                        </tr>

                        <tr
                        >
                            <td> Skill GOLD DROP</td>
                            <td> {{ kpisSupportGoldDrop }}</td>

                        </tr>

                        <tr
                        >
                            <td> Skill WAVE REDUCE</td>
                            <td> {{ kpiSupportWaveReduce }}</td>

                        </tr>

                        <tr
                        >
                            <td> Skill TAP DAMAGE TOTAL DPS</td>

                            <td> {{ kpiSupportTapDamageTotalDps }}</td>

                        </tr>

                        </tbody>


                    </template>

                </v-simple-table>

                <!-- Support level (temporary variable): {{ parseInt(kpiSupportPossibleLevelUp.ToDoubleIfPossible())}} -->


            </v-card>

        </v-dialog>

        <v-row>
            <!--        <v-card min-height="150"></v-card>-->
            <v-col cols="4">
                TTKG : <strong> {{ timeToKillGhost }}</strong> <br/>
                TTKB : <strong>{{ timeToKillBoss }}</strong> <br/>
                SPRINT LEVEL : <strong>{{ sprintLevel }}</strong> <br/>
                <!--        KPI : Next hero level <strong>{{this.heroBaseLevel + 1}} </strong><br />-->
                NTL : <strong>{{ this.heroBaseLevel + 1 }} </strong><br/>
                <!--          <v-icon class="mr-3" dense>mdi-currency-usd </v-icon>-->
                $GAP : <strong> {{ costHero.ToReadableAlphabetV2() }} </strong>

                <!--        KPI : Coins require to Level Up : <strong> {{costHero.ToReadableAlphabetV2()}} </strong><br />-->
                <!--        FT  : Ghost of level {{this.ghostLevel}} to kill : <strong> {{kpiFarmingRequiredToLevelUp}} </strong><br />-->
                <br/>
                FT<small>({{ this.ghostLevel }})</small> : <strong> {{ kpiFarmingRequiredToLevelUp }} </strong><br/>

            </v-col>

            <v-col cols="4">
                <!--        FTT : Time required from level {{this.heroBaseLevel}} to {{this.heroBaseLevel + 1}} : <strong> {{kpiTimeFarming}}  </strong> <br />-->

                FTT : <strong> {{ kpiTimeFarming }} </strong> <br/>
                <!--        KPI : Ghost HP at level {{this.ghostLevel}}  : <strong> {{kpiGhostHP}} </strong><br />-->
                GHP<small>({{ this.ghostLevel }})</small> : <strong> {{ kpiGhostHP }} </strong><br/>
                <!--        KPI : Hero base DMG at level {{this.heroBaseLevel}}  : <strong> {{kpiHeroBaseDmg}} </strong><br />-->
                DMG<small>({{ this.heroBaseLevel }})</small> : <strong> {{
                    kpiHeroBaseDmg.ToReadableAlphabetV2()
                }} </strong><br/>
                DPS<small>({{ this.heroBaseLevel }})</small> : <strong> {{
                    kpiHeroDps.ToReadableAlphabetV2()
                }} </strong><br/>
                WAVE: <strong>{{ kpiWaveValue }} </strong>


            </v-col>

            <v-col cols="4">
                KPI : &Sigma;G Standard {{ this.ghostLevel }} : <strong>
                {{ kpiCumulatedGoldWonAfterReachingGhostLevel.ToReadableAlphabetV2() }}</strong> <br/>

                KPI : &Sigma;GAP <small> {{ this.heroBaseLevel }}</small> : <strong>
                {{ kpiCumulatedSpentCoinsToLevelUp.ToReadableAlphabetV2() }}</strong>

                <br/> KPI : Money after spending &Sigma;GAP :

                <strong>{{ kpiRemainingMoneyToSpendAfterSpendingGAP.ToReadableAlphabetV2() }}</strong>
                <br/> KPI : Money for : heros, pets and items :
                <strong>{{ kpiRemainingMoneyToSpendAfterSpendingGAP.ToReadableAlphabetV2() }}</strong><br/>

                <br/> KPI : Money available for support (50%) : <strong>
                {{ kpiRemainingMoneyAvailableForSupport.ToReadableAlphabetV2() }}</strong>
                <br/> KPI : Number of support unlocked : <strong> {{ kpiSupportUnlocked }}</strong>
                <br/> KPI : Support possible levelup : <strong> {{
                    kpiSupportPossibleLevelUp.ToReadableAlphabetV2()
                }}</strong>
            </v-col>

            <!--        KPI : Cumulated time from level 1 to {{this.heroBaseLevel}} : <strong> {{kpiCumulatedFarmingTime}}  </strong> <br />-->
            <!--        KPI : Coins spent from lvl 1 to lvl {{this.heroBaseLevel}} : <strong> {{cumulatedCoinsToBaseLevelUp}} </strong>-->

            <!--        <v-avatar-->
            <!--            height="20"-->
            <!--            width="20"-->
            <!--        >-->
            <!--          <img-->

            <!--              :src="Tools.renderLocalPicture('ui/coins.png')"-->
            <!--          >-->
            <!--        </v-avatar>-->

        </v-row>

        <v-row
        >
            <v-col>
                <v-card
                    max-width="800"
                    min-width="800"
                >
                    <v-toolbar
                        flat
                        dense
                    >
                        <v-toolbar-title>
          <span class="subheading">
            <v-avatar>
          <img
              :src="Tools.renderLocalPicture('quest_icon/quest_icon_0013_boss.png')"
          >
        </v-avatar>
             {{ ghostLevel }}

            <v-avatar>
          <img
              :src="Tools.renderLocalPicture('heroes/HERO-head-09-motharo.png')"
          >
        </v-avatar>  {{ heroBaseLevel }}</span>


                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <!--        <v-btn icon>-->
                        <!--          <v-icon>mdi-share-variant</v-icon>-->
                        <!--        </v-btn>-->
                        <v-avatar
                            :color="balanceHeroGhost"
                            size="60"
                        >
                            <span class="white--text text-h5">{{ this.heroBaseLevel - this.ghostLevel }}</span>
                        </v-avatar>


                        1 Ghost will drop : {{ ghostDrop.ToReadableAlphabetV2() }}


                    </v-toolbar>

                    <v-card-text>
                        <v-row
                            class="mb-4"
                            justify="space-between"
                        >
                            <v-col class="text-left">
              <span
                  class="text-h4 font-weight-light"

              >HTKG:</span>
                                <span
                                    class="text-h3 font-weight-light"
                                    v-text="hitPerGhosts"
                                ></span>
                                <span class="subheading font-weight-light mr-1">TTKG ({{ timeToKillGhost }} )</span>


                            </v-col>

                        </v-row>


                        <v-slider
                            v-model="ghostLevel"
                            :color="color1"
                            track-color="grey"
                            always-dirty
                            min="1"
                            max="5000"
                            hint="level"
                            persistent-hint
                        >
                            <template v-slot:prepend>
                                <v-icon
                                    :color="color1"
                                    @click="decrement"
                                >
                                    mdi-minus
                                </v-icon>
                            </template>

                            <template v-slot:append>
                                <v-icon
                                    :color="color1"
                                    @click="increment"
                                >
                                    mdi-plus
                                </v-icon>
                            </template>
                        </v-slider>
                        <v-slider
                            v-model="heroBaseLevel"
                            :color="colorBaseHero"
                            track-color="grey"
                            always-dirty
                            min="1"
                            max="1000"
                            hint="Hero Base Level"
                            persistent-hint
                        >
                            <template v-slot:prepend>
                                <v-icon
                                    :color="colorBaseHero"
                                    @click="decrementBaseHero"
                                >
                                    mdi-minus
                                </v-icon>
                            </template>

                            <template v-slot:append>
                                <v-icon
                                    :color="colorBaseHero"
                                    @click="incrementBaseHero"
                                >
                                    mdi-plus
                                </v-icon>
                            </template>
                        </v-slider>

                        <v-btn
                            color="primary"
                            fab
                            small
                            dark
                            @click="oneShot()"
                        >
                            <v-icon>mdi-alien-outline</v-icon>
                        </v-btn>


                        <v-text-field
                            label="Ghost level direct input"
                            v-model="ghostLevel"
                            single-line
                            type="number"
                        />
                    </v-card-text>
                </v-card>
                <v-divider></v-divider>

            </v-col>


            <v-card
                max-width="500"
                min-width="500"
                min-height="800"
            >
                <v-tabs
                    v-model="tab"
                    background-color="deep-purple accent-4"
                    centered
                    dark
                    icons-and-text
                >
                    <v-tabs-slider></v-tabs-slider>

                    <v-tab href="#tab-support">
                        Support

                        <!-- <v-icon>mdi-currency-usd</v-icon> -->
                    </v-tab>

                    <v-tab href="#tab-1">
                        ???

                        <v-icon>mdi-currency-usd</v-icon>
                    </v-tab>

                    <v-tab href="#tab-2">
                        Pets
                        <v-icon>mdi-heart</v-icon>
                    </v-tab>

                    <!--          <v-tab href="#tab-3">-->
                    <!--            Achievements.-->
                    <!--            <v-icon>mdi-account-box</v-icon>-->
                    <!--          </v-tab>-->
                </v-tabs>

                <v-tabs-items v-model="tab"
                >

                    <v-tab-item
                        :key="3"
                        value='tab-support'
                    >

                        <v-card flat
                                class="scroll"
                                max-height="500"
                        >
                            <v-card-text> A DATA</v-card-text>
                            <v-list dense>
                                <v-subheader>SUPPORTS</v-subheader>

                                <v-row>
                                    <v-col>
                                        <v-list-item-group

                                            color="primary"
                                        >
                                            <v-list-item
                                                v-for="(item, i) in supportList"
                                                :key="i"
                                                @click="didClickOnSupport(item)"
                                            >
                                                <v-avatar>
                                                    <img
                                                        :src="Tools.renderLocalPicture('supports/'+item.icon)"
                                                    ></v-avatar>
                                                <v-list-item-content>
                                                    <v-list-item-title v-text="item.name"></v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list-item-group>
                                    </v-col>
                                    <v-col>

                                        <p class="font-weight-black"> GAMEPLAY KPI </p>
                                        <v-simple-table>


                                            <tr>
                                                <td> PLAYER TAP DAMAGE</td>
                                                <td> {{ kpiGameplayPlayerTapDamage }}</td>

                                            </tr>
                                            <tr>
                                                <td><span class="red"> SUPPORT DPS </span></td>
                                                <td> {{ kpiGameplaySupportDps }}</td>

                                            </tr>
                                            <tr>
                                                <td> PET DPS</td>
                                                <td> 0</td>

                                            </tr>
                                        </v-simple-table>

                                        <p class="font-weight-black"> TEAM SKILLS BONUS</p>
                                        <v-simple-table>


                                            <tr>
                                                <td> TAP_DAMAGE</td>
                                                <td> {{ kpiTeamSkillTapDamage }}</td>
                                            </tr>

                                            <tr
                                            >
                                                <td> SUPPORT_DAMAGE</td>
                                                <td> {{ kpiTeamSkillSupportDamage }}</td>
                                            </tr>

                                            <tr
                                            >
                                                <td> ALL_DAMAGE</td>
                                                <td> {{ kpiTeamSkillAllDamage }}</td>
                                            </tr>

                                            <tr
                                            >
                                                <td> CAT BONUS</td>
                                                <td> {{ kpiTeamSkillCatBonus }}</td>
                                            </tr>

                                            <tr
                                            >
                                                <td> CRITICAL CHANCE</td>
                                                <td> {{ kpiTeamSkillCriticalChance }}</td>
                                            </tr>

                                            <tr
                                            >
                                                <td> MANA BONUS</td>
                                                <td> {{ kpiTeamSkillManaBonus }}</td>
                                            </tr>

                                            <tr
                                            >
                                                <td> CRITICAL DAMAGE</td>
                                                <td> {{ kpiTeamSkillCriticalDamage }}</td>

                                            </tr>

                                            <tr
                                            >
                                                <td> GOLD DROP</td>
                                                <td> {{ kpiTeamSkillGoldDrop }}</td>

                                            </tr>

                                            <tr
                                            >
                                                <td> WAVE REDUCE</td>
                                                <td> {{ kpiTeamSkillWaveReduce }}</td>

                                            </tr>

                                            <tr
                                            >
                                                <td> TAP DAMAGE TOTAL DPS</td>

                                                <td> {{ kpiTeamSkillTapDamageTotalDps }}</td>

                                            </tr>
                                        </v-simple-table>
                                    </v-col>
                                </v-row>

                            </v-list>


                        </v-card>
                    </v-tab-item>

                    <v-tab-item
                        v-for="i in 2"
                        :key="i"
                        :value="'tab-' + i"
                    >
                        <v-card flat>
                            <v-card-text>{{ text }}</v-card-text>
                        </v-card>
                    </v-tab-item>
                </v-tabs-items>
            </v-card>

        </v-row>


    </v-container>


    <!--  </v-container>-->
</template>


<style>
@keyframes metronome-example {
    from {
        transform: scale(.5);
    }

    to {
        transform: scale(1);
    }
}

.scroll {
    overflow-y: auto;
}
</style>

<script>


import BalanceKpiUtils from "../../../utils/BalanceKpiUtils";
import Tools from "../../../utils/Tools";
import SMPNum from "../../../../src/SMPNum";
import SupportSkillsConstant from "../../../../src/constants/SupportSkillsConstants"
import SupportModelUtils from "../../../../src/dao/SupportModelUtils";
import SupportSkillsConstants from "@/constants/SupportSkillsConstants";


export default {

    data: () => ({
        items: [
            {text: 'Real-Time', icon: 'mdi-clock'},
            {text: 'Audience', icon: 'mdi-account'},
            {text: 'Conversions', icon: 'mdi-flag'},
        ],
        tab: null,
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',
        Tools: Tools,
        viewMinute: false,
        ghostLevel: 1,
        heroBaseLevel: 1,
        interval: null,
        isPlaying: false,
        balanceKpiUtils: null,
        showSupportDetail: false,
        selectedSupportDetail: null
    }),

    computed: {


        kpiGameplaySupportDps() {

            //we select the 4 most active support at a specific level.
            //the one with the strongest SUPPORT_DAMAGE.
            //then we loop and we compute all.

            return 0;

        },

        kpiGameplayPlayerTapDamage() {

            let tapDamagePercent = this.kpiTeamSkillTapDamage;


            let finalResult = SMPNum.multSmpNum(this.kpiHeroBaseDmg, new SMPNum(tapDamagePercent));
            finalResult = SMPNum.plus(finalResult, this.kpiHeroBaseDmg);

            return finalResult.ToReadableAlphabetV2();


        },


        kpiTeamSkillTapDamageTotalDps() {
            let teamSize = this.kpiSupportUnlocked;
            let teamLevel = this.kpiSupportPossibleLevelUp.ToDoubleValue();

            let skillPercent =
                SupportModelUtils.computeSkillBonus(SupportSkillsConstants.TAP_DAMAGE_TOTAL_DPS
                    , this.$store.state.balanceBuilder.skillList, teamLevel, teamSize);

            return skillPercent;
        },

        kpiTeamSkillWaveReduce() {
            let teamSize = this.kpiSupportUnlocked;
            let teamLevel = this.kpiSupportPossibleLevelUp.ToDoubleValue();

            let skillPercent =
                SupportModelUtils.computeSkillBonus(SupportSkillsConstants.WAVE_REDUCE
                    , this.$store.state.balanceBuilder.skillList, teamLevel, teamSize);

            return skillPercent;
        },

        kpiTeamSkillGoldDrop() {
            let teamSize = this.kpiSupportUnlocked;
            let teamLevel = this.kpiSupportPossibleLevelUp.ToDoubleValue();

            let skillPercent =
                SupportModelUtils.computeSkillBonus(SupportSkillsConstants.GOLD_DROP
                    , this.$store.state.balanceBuilder.skillList, teamLevel, teamSize);

            return skillPercent;
        },

        kpiTeamSkillCriticalDamage() {
            let teamSize = this.kpiSupportUnlocked;
            let teamLevel = this.kpiSupportPossibleLevelUp.ToDoubleValue();

            let skillPercent =
                SupportModelUtils.computeSkillBonus(SupportSkillsConstants.CRITICAL_DMG
                    , this.$store.state.balanceBuilder.skillList, teamLevel, teamSize);

            return skillPercent;
        },

        kpiTeamSkillManaBonus() {
            let teamSize = this.kpiSupportUnlocked;
            let teamLevel = this.kpiSupportPossibleLevelUp.ToDoubleValue();

            let skillPercent =
                SupportModelUtils.computeSkillBonus(SupportSkillsConstants.MANA_BONUS
                    , this.$store.state.balanceBuilder.skillList, teamLevel, teamSize);

            return skillPercent;
        },

        kpiTeamSkillCriticalChance() {
            let teamSize = this.kpiSupportUnlocked;
            let teamLevel = this.kpiSupportPossibleLevelUp.ToDoubleValue();

            let skillPercent =
                SupportModelUtils.computeSkillBonus(SupportSkillsConstants.CRITICAL_CHANCE
                    , this.$store.state.balanceBuilder.skillList, teamLevel, teamSize);

            return skillPercent;
        },

        kpiTeamSkillCatBonus() {
            let teamSize = this.kpiSupportUnlocked;
            let teamLevel = this.kpiSupportPossibleLevelUp.ToDoubleValue();

            let skillPercent =
                SupportModelUtils.computeSkillBonus(SupportSkillsConstants.CAT_BONUS
                    , this.$store.state.balanceBuilder.skillList, teamLevel, teamSize);

            return skillPercent;
        },

        kpiTeamSkillSupportDamage() {
            let teamSize = this.kpiSupportUnlocked;
            let teamLevel = this.kpiSupportPossibleLevelUp.ToDoubleValue();

            let skillPercent =
                SupportModelUtils.computeSkillBonus(SupportSkillsConstants.SUPPORT_DAMAGE
                    , this.$store.state.balanceBuilder.skillList, teamLevel, teamSize);

            return skillPercent;

        },


        kpiTeamSkillTapDamage() {
            let teamSize = this.kpiSupportUnlocked;
            let teamLevel = this.kpiSupportPossibleLevelUp.ToDoubleValue();

            let skillPercent =
                SupportModelUtils.computeSkillBonus(SupportSkillsConstants.TAP_DAMAGE
                    , this.$store.state.balanceBuilder.skillList, teamLevel, teamSize);

            return skillPercent;
        },

        kpiTeamSkillAllDamage() {
            let teamSize = this.kpiSupportUnlocked;
            let teamLevel = this.kpiSupportPossibleLevelUp.ToDoubleValue();

            let skillPercent =
                SupportModelUtils.computeSkillBonus(SupportSkillsConstants.ALL_DAMAGE
                    , this.$store.state.balanceBuilder.skillList, teamLevel, teamSize);

            return skillPercent;
        },

        kpiSupportTapDamageTotalDps() {
            if (this.selectedSupportDetail && this.selectedSupportDetail.data && this.kpiSupportPossibleLevelUp) {
                let percent = SupportModelUtils.getSupportCumulatedPercent(this.$store.state.balanceBuilder.skillList
                    , this.selectedSupportDetail.supportId
                    , SupportSkillsConstant.TAP_DAMAGE_TOTAL_DPS
                    , this.kpiSupportPossibleLevelUp.ToDoubleValue())

                return percent;
            }
            return 0;

        },

        kpiSupportWaveReduce() {
            if (this.selectedSupportDetail && this.selectedSupportDetail.data && this.kpiSupportPossibleLevelUp) {
                let percent = SupportModelUtils.getSupportCumulatedPercent(this.$store.state.balanceBuilder.skillList
                    , this.selectedSupportDetail.supportId
                    , SupportSkillsConstant.WAVE_REDUCE
                    , this.kpiSupportPossibleLevelUp.ToDoubleValue())

                return percent;
            }
            return 0;
        },

        kpisSupportGoldDrop() {
            if (this.selectedSupportDetail && this.selectedSupportDetail.data && this.kpiSupportPossibleLevelUp) {
                let percent = SupportModelUtils.getSupportCumulatedPercent(this.$store.state.balanceBuilder.skillList
                    , this.selectedSupportDetail.supportId
                    , SupportSkillsConstant.GOLD_DROP
                    , this.kpiSupportPossibleLevelUp.ToDoubleValue())

                return percent;
            }
            return 0;
        },

        kpiSupportCriticalDamage() {
            if (this.selectedSupportDetail && this.selectedSupportDetail.data && this.kpiSupportPossibleLevelUp) {
                console.log('ok');
                let percent = SupportModelUtils.getSupportCumulatedPercent(this.$store.state.balanceBuilder.skillList
                    , this.selectedSupportDetail.supportId
                    , SupportSkillsConstant.CRITICAL_DMG
                    , this.kpiSupportPossibleLevelUp.ToDoubleValue())

                return percent;
            }
            return 0;


        },

        kpiSupportManaBonus() {
            if (this.selectedSupportDetail && this.selectedSupportDetail.data && this.kpiSupportPossibleLevelUp) {
                console.log('ok');
                let percent = SupportModelUtils.getSupportCumulatedPercent(this.$store.state.balanceBuilder.skillList
                    , this.selectedSupportDetail.supportId
                    , SupportSkillsConstant.MANA_BONUS
                    , this.kpiSupportPossibleLevelUp.ToDoubleValue())

                return percent;
            }
            return 0;
        },


        kpiSupportCriticalChance() {
            if (this.selectedSupportDetail && this.selectedSupportDetail.data && this.kpiSupportPossibleLevelUp) {
                console.log('ok');
                let percent = SupportModelUtils.getSupportCumulatedPercent(this.$store.state.balanceBuilder.skillList
                    , this.selectedSupportDetail.supportId
                    , SupportSkillsConstant.CRITICAL_CHANCE
                    , this.kpiSupportPossibleLevelUp.ToDoubleValue())

                return percent;
            }
            return 0;

        },

        kpiSupportCatBonus() {
            if (this.selectedSupportDetail && this.selectedSupportDetail.data && this.kpiSupportPossibleLevelUp) {
                console.log('ok');
                let percent = SupportModelUtils.getSupportCumulatedPercent(this.$store.state.balanceBuilder.skillList
                    , this.selectedSupportDetail.supportId
                    , SupportSkillsConstant.CAT_BONUS
                    , this.kpiSupportPossibleLevelUp.ToDoubleValue())

                return percent;
            }
            return 0;
        },

        kpiSupportSkillAllDamage() {
            if (this.selectedSupportDetail && this.selectedSupportDetail.data && this.kpiSupportPossibleLevelUp) {
                console.log('ok');
                let percent = SupportModelUtils.getSupportCumulatedPercent(this.$store.state.balanceBuilder.skillList
                    , this.selectedSupportDetail.supportId
                    , SupportSkillsConstant.ALL_DAMAGE
                    , this.kpiSupportPossibleLevelUp.ToDoubleValue())

                return percent;
            }
            return 0;
        },

        kpiSupportSkillSupportDamage() {

            if (this.selectedSupportDetail && this.selectedSupportDetail.data && this.kpiSupportPossibleLevelUp) {
                let percent = SupportModelUtils.getSupportCumulatedPercent(this.$store.state.balanceBuilder.skillList
                    , this.selectedSupportDetail.supportId
                    , SupportSkillsConstant.SUPPORT_DAMAGE
                    , this.kpiSupportPossibleLevelUp.ToDoubleValue())

                return percent;
            }
            return 0;

        },


        kpiSupportSkillTapDamage() {
            if (this.selectedSupportDetail && this.selectedSupportDetail.data && this.kpiSupportPossibleLevelUp) {

                /*
                 let temp = this.$store.state.balanceBuilder.buildBonusSupportTapDamageForSupportID(this.selectedSupportDetail.supportId,this.kpiSupportPossibleLevelUp.ToDoubleValue());
                 //temp =1;

                 let label = "";
                 if (temp) {
                   temp.forEach(skill => {
                     label = label + " " + skill.skillNameEn + "" + skill.m_skillPercent  + " " + skill.m_iCurrentLevel;
                    label = label + ' ' + skill.percentValueWithEvolve;
                   })
                   return label ;
                 }else {
                   //return temp;
                 }*/

                let percent = SupportModelUtils.getSupportCumulatedPercent(this.$store.state.balanceBuilder.skillList
                    , this.selectedSupportDetail.supportId
                    , SupportSkillsConstant.TAP_DAMAGE
                    , this.kpiSupportPossibleLevelUp.ToDoubleValue())

                return percent;

                //return this.selectedSupportDetail.supportId;
            } else {
                return 0;
            }
        },

        kpiSupportTapDamage() {

            //how to get the KPI Support tap Damaage
            let possibleLevelUp = this.kpiSupportPossibleLevelUp.ToDoubleValue();
            possibleLevelUp = parseInt(possibleLevelUp);

            let temp = this.$store.state.balanceBuilder.gameplay.DMGStandardSupport(possibleLevelUp);
            return temp;

        },

        kpiSupportDpsStandard() {

            let temp = new SMPNum(1);
            let possibleLevelUp = this.kpiSupportPossibleLevelUp.ToDoubleValue();
            possibleLevelUp = parseInt(possibleLevelUp);

            temp = this.$store.state.balanceBuilder.gameplay.DMGStandardSupport(possibleLevelUp);
            return temp;
            //return possibleLevelUp;

        },

        kpiSupportLabelInfos() {
            let res = "";
            if (this.selectedSupportDetail !== null) {
                res = this.selectedSupportDetail.name;
            }
            return res;
        },

        kpiSupportPossibleLevelUp() {
            let temp = this.$store.state.balanceBuilder.innerFunctionBuildPossibleLevelUpForSupport(this.kpiRemainingMoneyAvailableForSupport, this.kpiSupportUnlocked)
            return temp;
        },

        kpiHeroDps() {
            let temp = this.$store.state.balanceBuilder.gameplay.DMGHero(this.heroBaseLevel);
            return SMPNum.multSmpNum(temp, new SMPNum(this.$store.state.ratios.hitPerSeconds));
        },


        kpiSupportUnlocked() {
            let x = this.$store.state.balanceBuilder.gameplay.GetNumberOfSupportUnlockable(this.kpiRemainingMoneyAvailableForSupport, 0);
            return x;
        },

        kpiRemainingMoneyAvailableForSupport() {
            let ratioAllocatedToSupport = 50;
            if (!this.kpiRemainingMoneyToSpendAfterSpendingGAP.isZero) {
                let result = SMPNum.multSmpNum(new SMPNum(ratioAllocatedToSupport), this.kpiRemainingMoneyToSpendAfterSpendingGAP);
                return SMPNum.divSmpNum(result, new SMPNum(100));
            } else {
                return new SMPNum(0);
            }
        },

        kpiRemainingMoneyToSpendAfterSpendingGAP() {

            if (this.kpiCumulatedGoldWonAfterReachingGhostLevel && this.kpiCumulatedSpentCoinsToLevelUp) {
                if (SMPNum.greaterThan(this.kpiCumulatedGoldWonAfterReachingGhostLevel, this.kpiCumulatedSpentCoinsToLevelUp)) {
                    let temp = SMPNum.minus(this.kpiCumulatedGoldWonAfterReachingGhostLevel, this.kpiCumulatedSpentCoinsToLevelUp);
                    return temp;
                } else {
                    //let temp = SMPNum.minus(this.cumulatedCoinsToBaseLevelUp,this.cumulatedCoinsWonAfterReachGhostLevel);
                    //return temp;
                    return new SMPNum(0);
                }
            }
            return new SMPNum(0);


        },

        supportList() {

            let availableSupport = SupportModelUtils.getUnlockedTeam(this.$store.state.skillList
                , this.kpiSupportUnlocked);
            return availableSupport;

            /*
            console.log('SUPPORT LIST COMPUTER');
            console.log(this.$store.state);
            //Tools.groupBy()
            let listGrouped = Tools.groupBy(this.$store.state.skillList, 'support', null);

            console.log('DEBUG SUPPORT LIST COPMUTED');
            console.log(listGrouped);
            //let supportNames =  Object.keys(listGrouped)
            let totalSupport = this.kpiSupportUnlocked;

            let availableSupport = [];
            //need to group and ordered the support list.
            let supportNames = Object.keys(listGrouped);
            console.log(supportNames);
            supportNames.forEach(s => {
              let support = listGrouped[s];
              console.log(s);
              console.log(support);
              let supportId = support[0].supportData.m_iID
              if (supportId <= totalSupport) {
                let supportData = {};
                supportData.name = s;
                supportData.icon = supportId + '.png';
                supportData.data = support;
                supportData.supportId = supportId;
                availableSupport.push(supportData);
              }
            })
            return availableSupport;
            //return Object.keys(listGrouped);
            */

        },
        kpiCumulatedSpentCoinsToLevelUp() {
            let sum = this.$store.state.balanceBuilder.gameplay.ComputeSumGeometricForSeries('CostHero', this.heroBaseLevel)
            //return sum.ToReadableAlphabetV2();
            return sum;
        },

        kpiCumulatedSpentCoinsToLevelUpSupport() {
            //TODO parse the value.
            let supportValue = this.kpiSupportPossibleLevelUp.ToDoubleIfPossible();
            let sum = this.$store.state.balanceBuilder.gameplay.ComputeSumGeometricForSeries('CostSupport', supportValue);
            return sum;
        },

        kpiCumulatedGoldWonAfterReachingGhostLevel() {
            //somme g�om�trique du drop coins. * farming.
            let sum = this.$store.state.balanceBuilder.gameplay.ComputeSumGeometricForSeries('DropCoins', this.ghostLevel)

            //sum = SMPNum.multSmpNum(new SMPNum(this.$store.state.balanceBuilder.K_FARMING_SIZE),sum);
            sum = SMPNum.multSmpNum(sum, new SMPNum(10));

            //this.$store.state.balanceBuilder.gameplay.ComputeSumGeometricForSeries('DropCoins',this.ghostLevel)
            //return sum.ToReadableAlphabetV2();

            let goldFromQuest = this.$store.state.balanceBuilder.gameplay.ComputeGoldGeneratedByQuestAtLevel(this.ghostLevel);
            return SMPNum.plus(sum, goldFromQuest);

        },

        cumulatedCoinsToBaseLevelUp() {

            let sum = this.$store.state.balanceBuilder.gameplay.ComputeSumGeometricForSeries('CostHero', this.heroBaseLevel)

            let firstValue = this.$store.state.balanceBuilder.gameplay.GetFirstTermValueBySeriesName('CostHero');
            if (SMPNum.greaterThan(sum, firstValue)) {
                let res = SMPNum.minus(sum, firstValue);
                return res.ToReadableAlphabetV2();
            } else {
                return 0;
            }


            //return res.ToReadableAlphabetV2();

        },

        kpiCumulatedFarmingTime() {
            //il faut recuperer le temps pur chaque niveau
            // et faire quoi, faire un cumul.
            // le cumul de l'argent. la somme g�moetrique

            //etla c'st un pb car avec ce cumul, de sous d�pens�.
            // je ne peux pas calculeter le temps moyen passer � spawn
            //sauf si on part du principe que le temps est le meme

            // on prends le temps au niveau 1
            // le temps au niveau heroBaseLevel
            // on prends le temps median , on calcule le temps pour tous les niveaux.

            // on prends le drop median des ghosts
            // on divise la sum d'argent par le drop median des ghost pour avoir le nombre de ghost � tuer
            // le nombre median de ghost � tuer, on le multiplie par le temps median

            let sum = this.$store.state.balanceBuilder.gameplay.ComputeSumGeometricForSeries('CostHero', this.heroBaseLevel)

            let firstValue = this.$store.state.balanceBuilder.gameplay.GetFirstTermValueBySeriesName('CostHero');
            if (SMPNum.greaterThan(sum, firstValue)) {
                let res = SMPNum.minus(sum, firstValue);
                return res.ToReadableAlphabetV2();
            } else {
                return 0;
            }

        },


        kpiWaveValue() {

            return Math.floor(this.ghostLevel / 450) + 10;


        },
        kpiTimeFarming() {
            let t = this.balanceKpiUtils.getTimeToKillGhostInSMPNum(this.ghostLevel, this.heroBaseLevel, this.$store.state.ratios.hitPerSeconds);
            let farming = SMPNum.divSmpNum(this.costHero, this.ghostDrop)
            let total = SMPNum.multSmpNum(t, farming);

            return SMPNum.ToReadableTimeValue(total);

            //return total.ToReadableAlphabetV2() +  unit;
        },

        kpiGhostHP() {
            let temp = this.$store.state.balanceBuilder.gameplay.HPGhost(this.ghostLevel);
            return temp.ToReadableAlphabetV2();
        },

        kpiHeroBaseDmg() {
            let temp = this.$store.state.balanceBuilder.gameplay.DMGHero(this.heroBaseLevel);
            //return temp.ToReadableAlphabetV2();
            return temp;
        },

        kpiFarmingRequiredToLevelUp() {
            //comment on calcule
            // il nous faut le cost hero.
            // il nous faut combien de drop le ghost


            let res = SMPNum.divSmpNum(this.costHero, this.ghostDrop)
            return res.ToReadableAlphabetV2();
        },

        ghostDrop() {
            let res = this.$store.state.balanceBuilder.gameplay.DropCoins(this.ghostLevel);
            //return res.ToReadableAlphabetV2();
            return res;
        },

        costHero() {
            //return this.$store.state.balanceBuilder.gameplay.CostHero(this.heroBaseLevel).ToReadableAlphabetV2();

            //return this.$store.state.balanceBuilder.calcUtilsLevelUp2(new SMPNum(1),new SMPNum(this.heroBaseLevel),new SMPNum(1),'CostHero');

            //let temp = new SMPNum(1);
            ////console.log(temp);
            //let res = this.$store.state.balanceBuilder.calcUtilsGeomoetricCumulation(1, this.heroBaseLevel, 'CotHero', 1);


            //let res = this.$store.state.balanceBuilder.gameplay.ComputeSumForWholeSeries('CostHero',this.heroBaseLevel)

            let res = this.$store.state.balanceBuilder.gameplay.CostHero(this.heroBaseLevel + 1);


            return res;
        },

        balanceHeroGhost() {
            if (this.ghostLevel < this.heroBaseLevel) return 'green'
            return 'red'
        },

        timeToKillGhost() {
            if (this.balanceKpiUtils) {

                //let mode = 's';
                // if (this.viewMinute) {
                //   mode = 'm';
                // }

                let time = this.balanceKpiUtils.getTimeToKillGhost(this.ghostLevel, this.heroBaseLevel, this.$store.state.ratios.hitPerSeconds)
                return time;

            } else {
                return 0;
            }

        },

        timeToKillBoss() {
            if (this.balanceKpiUtils) {
                let time = this.balanceKpiUtils.getTimeToKillBoss(this.ghostLevel, this.heroBaseLevel, this.$store.state.ratios.hitBossPerSeconds);
                return time;
            } else {
                return 0;
            }
        },

        sprintLevel() {
            // comment on calcule le sprint level
            // le sprint level va �tre calcul� par rapport � :
            // inputs :
            // level of the player .
            // on va chercher le boss level.
            // on calcule son TTKB
            // on v�rifie quand est-ce que ca d�passe.
            // on applique l'algorithme de la division par 2.
            let bossLevel = this.ghostLevel + 1;
            let time = this.balanceKpiUtils.getTimeToKillBossValueOnly(bossLevel, this.heroBaseLevel, this.$store.state.ratios.hitBossPerSeconds)
            let temp = 0;
            let oneHour = new SMPNum(1500);
            while (temp < 1000 && !SMPNum.greaterThan(time, oneHour)) {
                temp++;
                time = this.balanceKpiUtils.getTimeToKillBossValueOnly(bossLevel, this.heroBaseLevel, this.$store.state.ratios.hitBossPerSeconds)
                bossLevel = bossLevel + 1;
            }
            let label = 'Can reach level' + bossLevel + '(TTKB: ' + SMPNum.ToReadableTimeValue(time) + ')';
            //return temp;
            if (temp === 0) {
                label = 'Impossible';
            }
            return label;
        },

        hitPerGhosts() {
            return this.computeHitPerGhosts();

        },

        color1() {
            if (this.ghostLevel < 100) return 'indigo'
            if (this.ghostLevel < 125) return 'teal'
            if (this.ghostLevel < 140) return 'green'
            if (this.ghostLevel < 175) return 'orange'
            return 'red'
        },

        colorBaseHero() {
            if (this.heroBaseLevel < 100) return 'indigo'
            if (this.heroBaseLevel < 125) return 'teal'
            if (this.heroBaseLevel < 140) return 'green'
            if (this.heroBaseLevel < 175) return 'orange'
            return 'red'
        },

        animationDuration() {
            return `${60 / this.ghostLevel}s`
        },
    },

    created() {
        //console.log('did call created on KPI Dashboard');
        //console.log(this.$store.state);

        this.balanceKpiUtils = new BalanceKpiUtils(this.$store.state.balanceBuilder);
        this.ghostLevel = this.$store.state.ui.kpi.bpm;
        this.heroBaseLevel = this.$store.state.ui.kpi.heroBaseLevel;
        //console.log(this.ghostLevel);
        //console.log(this.heroBaseLevel);
    },

    methods: {


        computeMaximumLevelAchievable() {
            //on prends le ghost level
            //on prends le sigmaGAP pour un hero level matching level.
            //on prends le sigmaGS
            // si le sigmaGS > sigmaGAP(level) => on fait la difference
            // sinon
            // heroLevel = ghostLevel /2
            // sgimaGS > sgimaGAP(level) => difference
            // ghostLevel
            // ghostLevel / 2
            // droite ou gauche
            // target + (ghostLevel - target) / 2


            // on prends tous l'argent accumul� avec un ghost * 10
            //kpiCumulatedGoldWonAfterReachingGhostLevel

            //gauche => si on a 0
            // droite => si on a  de la valeur

            //startingPoint = 1
            //target = ghostLevel
            // if gauche =>
            // target = target / 2
            // if droite
            // startingPoint = target
            // target = target + (ghostLevel - target) / 2

            //until abs =target - startingPOint == 1 || - 1

            let found = false;

            let target = Math.floor(this.ghostLevel / 2);
            let previousTarget = this.ghostLevel;
            let securityLoop = 1;
            let levelToReturn = -1;

            do {
                let remaining = this.computeRemainingMoney(target);
                let goLeft = remaining.isZero;
                if (!goLeft) {
                    levelToReturn = target;
                }
                let temp = target;
                if (goLeft) {
                    target = target - Math.floor((Math.abs(target - previousTarget)) / 2);
                } else {
                    target = target + Math.floor((Math.abs(previousTarget - target)) / 2);
                }
                previousTarget = temp;
                if (previousTarget == target) {
                    found = true;
                }
                securityLoop++;
            } while (!found && securityLoop < 100);
            return levelToReturn;

        },

        computeRemainingMoney(heroLevel) {
            if(heroLevel > 0){
                let tempMoneySpent = this.$store.state.balanceBuilder.gameplay.ComputeSumGeometricForSeries('CostHero', heroLevel);
                let goldWon = this.kpiCumulatedGoldWonAfterReachingGhostLevel;
                if (goldWon && tempMoneySpent) {
                    if (SMPNum.greaterThan(goldWon, tempMoneySpent)) {
                        let temp = SMPNum.minus(goldWon, tempMoneySpent);
                        return temp;
                    } else {
                        //let temp = SMPNum.minus(this.cumulatedCoinsToBaseLevelUp,this.cumulatedCoinsWonAfterReachGhostLevel);
                        //return temp;
                        return new SMPNum(0);
                    }
                }
            }
            return new SMPNum(0);
        },

        computeHitPerGhosts() {
            if (this.balanceKpiUtils) {
                return this.balanceKpiUtils.getTotalHitsRequiredToKillAGhostAtLevel(this.ghostLevel, this.heroBaseLevel)
            } else {
                return 0;
            }
        },


        oneShot() {
            //TODO calculaote the onehsot

            //need to calculate the new ghostLevel.


            let temp = this.computeMaximumLevelAchievable();
            if (temp > 0) {
                this.heroBaseLevel = temp;
            } else {
                this.heroBaseLevel = this.ghostLevel;
            }

            this.saveUi();
            // let temp = this.computeHitPerGhosts();
            // let max = 100;
            // while (temp > 1 && max > 0) {
            //   max --;
            //   temp  = this.computeHitPerGhosts();
            //   this.heroBaseLevel ++;
            // }

        },

        saveUi() {
            //console.log('kpi save UI');
            let param = {
                uiScreen: 'kpi',
                uiValue: {
                    bpm: this.ghostLevel,
                    heroBaseLevel: this.heroBaseLevel
                }
            }
            this.$store.commit('saveUi', param);
        },

        decrement() {
            if (this.ghostLevel > 1) {
                this.ghostLevel--
                //this.$store.state.ui.kpi.ghostLevel = this.ghostLevel;
                this.saveUi();
            }

        },

        //events
        increment() {
            this.ghostLevel++
            this.saveUi();
        },
        decrementBaseHero() {
            if (this.heroBaseLevel > 1) {
                this.heroBaseLevel--;
                this.saveUi();
            }

        },

        incrementBaseHero() {
            this.heroBaseLevel++;
            this.saveUi();
        },

        toggle() {
            this.isPlaying = !this.isPlaying

        },
        didClickOnSupport(p) {
            console.log(p);
            this.showSupportDetail = true;
            this.selectedSupportDetail = p;
        },

    },
}
</script>
